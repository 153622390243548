export const NavbarLinks = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Tournaments",
    path: '/catalog',
  },
  {
    title: "AboutUs",
    path: "/about",
  },
  {
    title: "ContactUs",
    path: "/contact",
  },
];
